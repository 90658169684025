import React from "react";
import Section from "../../common/section/section";
import Container from "../../common/container/container";
import "./instagram.css";
import Button from "../../common/button/button";
import igphone from "../../pictures/ig_phone.png";

const Instagram = () => {
  return (
    <Section className="instagram" id={"portfolio"}>
      <Container>
        <img src={igphone} alt="phone" className="instagram_phone" />
        <div>
          <h1>
            See all my work, and keep in touch <br />
            on instagram{" "}
            <span role="img" aria-label="camera">
              📸
            </span>
          </h1>
          <p>
            I post my work on instagram; you can also message me there if you
            have any questions{" "}
            <span role="img" aria-label="thumbs up">
              👍🏼
            </span>
          </p>

          <Button
            type="light"
            is_link
            to="https://www.instagram.com/danny_rolfe/"
          >
            @danny_rolfe
          </Button>
        </div>
      </Container>
    </Section>
  );
};

export default Instagram;
