import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Header from "./components/header/header";
import Bio from "./components/bio/bio";
import Tattoo from "./components/tattoo/tattoo";
import ArtPrints from "./components/art_prints/art_prints";
import ArtCommission from "./components/art_comission/art_commission";

import blurts from "./pictures/blurts.png";
import Instagram from "./components/instagram/instagram";
import Footer from "./components/footer/footer";
import Store from "./components/store/store";

function App() {
  return (
    <div className="app">
      <Router>
        <div>
          <Route exact path="/" component={app} />
          <Route path="/store" component={Store} />
        </div>
      </Router>
    </div>
  );
}

const app = () => {
  return (
    <div>
      <div className="blurt_wrapper">
        <img src={blurts} alt="blurts" className="blurts blurts_1" />
        <img src={blurts} alt="blurts" className="blurts blurts_2" />
      </div>
      <Header />
      <Bio />
      <Tattoo />
      <ArtPrints />
      <ArtCommission />
      <Instagram />
      <Footer />
    </div>
  );
};

export default App;
