import React from "react";
import Section from "../../common/section/section";
import Container from "../../common/container/container";
import "./tattoo.css";
import Button from "../../common/button/button";
import useScrollPosition from "use-scroll-position";

const Tattoo = () => {
  const scrollPosition = useScrollPosition();

  return (
    <Section
      id={"tattoo_comission"}
      className="tattoo"
      style={{
        backgroundPositionY: `${Math.floor(scrollPosition * 0.4 - 250)}px`
      }}
    >
      <Container>
        <div>
          <h1 id="tattoo_comission">
            Get a custom tattoo design{" "}
            <span role="img" aria-label="dragon">
              🐲
            </span>
          </h1>
          <p>
            I will work with you to create exactly the tattoo you want. Whether
            you already have an idea, or want to talk it through together, I can
            help. Pricing will be agreed upon before I begin. Once the design is
            completed, I will send you a high- quality print. Price will of
            course depend on the complexity of your chosen design.
          </p>
          <p>
            Don’t hesitate to message me! I love this stuff so you can get in
            touch if you're just considering a tattoo and we can have a chat
            about it.
          </p>

          <Button is_link to={email_string}>
            Contact me{" "}
            <span role="img" aria-label="envelope">
              ✉️
            </span>
          </Button>
        </div>
      </Container>
    </Section>
  );
};

const email_recipient = "askdannyrolfe@gmail.com";
const email_subject = encode_spaces(
  "I want us to make a tattoo design together, Daniel!"
);
const email_body = encode_spaces(
  "Hi, I saw your website and would like to make a tattoo design with you!%0D%0A%0D%0AI was inspired by the following pieces of yours: %0D%0A%0D%0A%0D%0ABest regards,%0D%0AYour name :-)"
);
const email_string = `mailto:${email_recipient}?subject=${email_subject}&body=${email_body}`;

function encode_spaces(string) {
  return string.split(" ").join("%20");
}

export default Tattoo;
