import React from "react";
import "./button.css";

const Button = ({ children, className, type, to, is_link }) => {
  const btnClass =
    type === "light"
      ? `button ${className} button_light`
      : `button ${className}`;

  if (is_link) {
    return (
      <a
        href={to}
        className={btnClass}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  } else {
    return (
      <button onClick={() => console.log("Clicked")} className={btnClass}>
        {children}
      </button>
    );
  }
};

export default Button;
