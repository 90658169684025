import React from "react";
import Section from "../../common/section/section";
import Container from "../../common/container/container";
import "./art_commission.css";
import Button from "../../common/button/button";
import useScrollPosition from "use-scroll-position";

const ArtCommission = () => {
  const scrollPosition = useScrollPosition();

  return (
    <Section
      className="art_commission"
      id="art_comission"
      style={{
        backgroundPositionY: `${Math.floor(scrollPosition * 0.4 - 800)}px`
      }}
    >
      <Container>
        <div>
          <h1>
            Commission your own piece of art{" "}
            <span role="img" aria-label="fountain pen">
              🖋
            </span>
          </h1>
          <p>
            If you want something fully unique based on an idea you have, we can
            work together to make that a reality.
          </p>
          <p>
            I'll need a rough idea of what you like and the design of mine that
            caught your eye. This will help me gauge what style to go for. You
            can send me a message and we will take it from there.
          </p>

          <Button is_link to={email_string}>
            Contact me{" "}
            <span role="img" aria-label="envelope">
              ✉️
            </span>
          </Button>
        </div>
      </Container>
    </Section>
  );
};

const email_recipient = "askdannyrolfe@gmail.com";
const email_subject = encode_spaces(
  "I want to make a piece of art together, Daniel!"
);
const email_body = encode_spaces(
  "Hi, I saw your website and would like to make a piece of art with you!%0D%0A%0D%0AI was inspired by the following pieces of yours: %0D%0A%0D%0A%0D%0ABest regards,%0D%0AYour name :-)"
);
const email_string = `mailto:${email_recipient}?subject=${email_subject}&body=${email_body}`;

function encode_spaces(string) {
  return string.split(" ").join("%20");
}

export default ArtCommission;
