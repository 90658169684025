import React, { useState } from "react";
import "./store.css";
import Container from "../../common/container/container";
import Header from "../header/header";
import Footer from "../footer/footer";

import print_1 from "../../pictures/conquer.JPEG";
import print_2 from "../../pictures/broken.JPEG";
import print_3 from "../../pictures/split.JPEG";

import Button from "../../common/button/button";

const Store = () => {
  const [active, set_active] = useState(null);
  return (
    <div>
      <Header />
      <div className="store">
        <h1>Original art and prints store 👨🏼‍🎨</h1>
        <p>Click a photo to see it in my Etsy store 👌🏼</p>
        <div className="store_items">
          {items.map((item, index) =>
            StoreItem(item, index, active, set_active)
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

const StoreItem = (item, index, active, set_active) => {
  return (
    <div
      className={` ${index === active && "active_item"}`}
      onClick={() => {
        if (index === active) {
          set_active(null);
        } else {
          set_active(index);
        }
      }}
    >
      <img className="item_img" src={item.img} alt="photo" />
      <p className="item_name">{item.name}</p>
      {index === active && <p>{item.description}</p>}
      <p className="price">
        £{item.price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}
      </p>
      {index === active && (
        <Button is_link to={item.link}>
          Buy on Etsy
        </Button>
      )}
    </div>
  );
};

const items = [
  {
    img: print_1,
    name: "Conquer Evil",
    description: "I wanted this piece to depict  an epic battle between good and evil. I took inspiration from  Indonesian statues as I love the details each of them have. I wanted the work to have a powerful message so I incorporated the words from Romans 12:21.",
    price: 40,
    link: "https://www.etsy.com/uk/listing/756986328/conquer-evil?ref=shop_home_active_1&frs=1"
  },
  {
    img: print_2,
    name: "Broken",
    description: "This drawing was to emulate the feelings of someone's journey through life and the many different paths it can take you on.  “Insert inspiring instagram quote here” The world may try and break you but you need to mend yourself and push on.",
    price: 30,
    link: "https://www.etsy.com/uk/listing/770870157/broken?ref=shop_home_active_2&frs=1"
  },
  {
    img: print_3,
    name: "Split",
    description: "Sometimes feelings can make your mind spin. Confusion and uncertainty was the inspiration behind this piece. I wanted this drawing to stand out and make people think about the meaning behind it.",
    price: 25,
    link: "https://www.etsy.com/uk/listing/756989178/split?ref=shop_home_active_3&frs=1"
  },
 {
    img: "https://res.cloudinary.com/dmzpzv2vi/image/upload/v1580834488/Listings/Gothic%20Angel/Gothic_Angel_as1jja.jpg",
    name: "Gothic Angel",
    description: "This is the largest drawing I have completed to date. I remember the day I thought of this design. It had to be big. I spent hours planning out the design, making sure to get the spine, wings and lots of extra little details into it. I was really happy with the result.",
    price: 50,
    link: "https://www.etsy.com/uk/listing/762166414/gothic-angel?ref=shop_home_active_4&frs=1"
  },
 {
    img: "https://res.cloudinary.com/dmzpzv2vi/image/upload/v1580834504/Listings/cerberus/Cerberus_nn6zb6.jpg",
    name: "Cerberus",
    description: "Not much needs to be said about the inspiration behind this piece. I have always been fascinated with the stories from Greek mythology and wanted to have a go at drawing everyone's favourite hellhound.",
    price: 30,
    link: "https://www.etsy.com/uk/listing/762167628/cerberus?ref=shop_home_active_5&frs=1"
   },
 {
    img: "https://res.cloudinary.com/dmzpzv2vi/image/upload/v1580834487/Listings/medusa/medusa_n1agcg.jpg",
    name: "Medusa",
    description: "Another piece that has been inspired by Greek mythology. I wanted to capture Medusa in a rougher, more modern tone compared to how she is usually portrayed. I wanted her to have face tattoos, snake bites and a stare that could really turn you to stone.",
    price: 25,
    link: "https://www.etsy.com/uk/listing/776043159/medusa?ref=shop_home_active_6&frs=1"
    },
 {
    img: "https://res.cloudinary.com/dmzpzv2vi/image/upload/v1580834494/Listings/Angus/Angus_nqbmbq.jpg",
    name: "Angus",
    description: "Being from Scotland, I felt it necessary to include my rendition of the world's most stylish cow. Not the basic cows you get everywhere else. I'm talking about cows with more volume than your aunt in the 80’s . The Scottish Highland coo.",
    price: 35,
    link: "https://www.etsy.com/uk/listing/773358355/angus?ref=shop_home_active_7&frs=1"
   },
 {
    img: "https://res.cloudinary.com/dmzpzv2vi/image/upload/v1580834490/Listings/snake%20and%20dagger/snake_and_dagger_msjbvc.jpg",
    name: "Snake and Dagger",
    description: "I was asked to design this piece by a soldier. The dagger is a British stiletto fighting knife. I wanted to convey the idea that soldiers are our protectors. The apple and snake represent sin/evil and the dagger represents our soldiers fighting against that.",
    price: 40,
    link: "https://www.etsy.com/uk/listing/762165046/snake-and-dagger?ref=shop_home_active_8&frs=1"
   },
 {
    img: "https://res.cloudinary.com/dmzpzv2vi/image/upload/v1580834499/Listings/aztech%20bird/aztec_bird_vhjydi.jpg",
    name: "Aztec Bird",
    description: "I can't actually take all of the credit for this idea. My partner woke up one morning and told me about a dream she was having where she was getting an Aztec bird tattooed on her. After describing it to me I had to get out the art supplies and go to work.",
    price: 35,
    link: "https://www.etsy.com/uk/listing/773359581/aztec-bird?ref=shop_home_active_9&frs=1"
  }
];

export default Store;
