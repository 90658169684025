import React from "react";
import Section from "../../common/section/section";
import Container from "../../common/container/container";

import avatar from "../../pictures/portrait.jpg";

import "./bio.css";

const Bio = () => {
  return (
    <Section className="bio">
      <Container>
        <img className="bio_avatar" src={avatar} alt="My face" />
        <div>
          <h1>
            Hi, I'm Danny{" "}
            <span role="img" aria-label="wave">
              👋
            </span>
          </h1>
          <p className="bio_body">
            I'm based in Glasgow and I'm dedicated to finding a way to draw
            anything you want! I love making people's ideas a reality and put 
            my all into each piece.
          </p>
        </div>
      </Container>
    </Section>
  );
};

export default Bio;
