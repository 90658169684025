import React from "react";
import { Link } from "react-router-dom";
import Section from "../../common/section/section";
import Container from "../../common/container/container";
import "./art_prints.css";
import Button from "../../common/button/button";
import ImageCarousel from "./components/image_carousel/image_carousel";

const ArtPrints = () => {
  return (
    <Section className="art_prints" id="art_and_prints">
      <Container>
        <ImageCarousel />
        <div>
          <h1>
            Do your walls feel empty?{" "}
            <span role="img" aria-label="paint">
              🎨
            </span>
          </h1>
          <p>
            I sell some of my original artwork, and limited runs of high quality
            prints. Click below to check it out!
          </p>

          <Link to="/store">
            <Button type="light">
              Go to shop{" "}
              <span role="img" aria-label="painting">
                🖼
              </span>
            </Button>
          </Link>
        </div>
      </Container>
    </Section>
  );
};

export default ArtPrints;
