import React from "react";
import "./image_carousel.css";
// import print_1 from "../../../../pictures/1.jpg";
import print_1 from "../../../../pictures/split.JPEG";
// import print_2 from "../../../../pictures/2.jpg";
import print_2 from "../../../../pictures/split_zoom.jpg";
// import print_3 from "../../../../pictures/3.jpg";
import print_3 from "../../../../pictures/conquer_zoom.jpg";
import print_4 from "../../../../pictures/conquer.JPEG";
import print_5 from "../../../../pictures/broken_zoom.jpg";

const ImageCarousel = () => {
  return (
    <div className="image_carousel">
      <div className="image_carousel_rail">
        <img src={print_1} id="img1" alt="" />
        <img src={print_2} id="img2" alt="" />
        <img src={print_3} id="img3" alt="" />
        <img src={print_4} id="img4" alt="" />
        <img src={print_5} id="img5" alt="" />
        <img src={print_1} id="img6" alt="" />
        <img src={print_2} id="img7" alt="" />
        <img src={print_3} id="img8" alt="" />
        <img src={print_4} id="img9" alt="" />
        <img src={print_5} id="img10" alt="" />
      </div>
    </div>
  );
};

export default ImageCarousel;
