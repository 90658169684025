import React from "react";
import "./footer.css";
import Container from "../../common/container/container";

const date = new Date();
const year = date.getFullYear();

const Footer = () => {
  return (
    <Container className="footer">
      <h1 className="logo">
        <a href="/">Daniel Rolfe</a>
      </h1>
      <div className="footer-items">
        <p>
          <a href="mailto:askdannyrolfe@gmail.com?subject=Hi%20Daniel!">
            email me{" "}
            <span role="img" aria-label="envelope">
              💌
            </span>
          </a>
        </p>
        <p>© Copyright {year}</p>
      </div>
    </Container>
  );
};

export default Footer;
