import React from "react";
import "./section.css";

const Section = ({ children, className, style, id }) => {
  return (
    <div style={style} className={"section " + className} id={id}>
      {children}
    </div>
  );
};

export default Section;
