import React from "react";
import "./header.css";
import Container from "../../common/container/container";

const Header = () => {
  return (
    <Container className="header">
      <h1 className="logo">
        <a href="/">Daniel Rolfe</a>
      </h1>
      <div className="header-items">
        <a href="#tattoo_comission">Tattoo Commisions</a>
        <a href="#art_and_prints">Wall Art & prints</a>
        <a href="#art_comission">Art Commissions</a>
        <a href="#portfolio">Portfolio</a>
      </div>
    </Container>
  );
};

export default Header;
